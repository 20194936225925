import { makeRequest } from "../shared/request-service";
import { METHODS } from "../shared/requests.constants";
import { AUTH_ENDPOINTS } from "./auth.constant";

export const checkAutoLogin = () => {
  try {
    const jwt = localStorage.getItem("accesstoken");
    if (!jwt) return false;
    const decodedJwt = JSON.parse(atob(jwt.split(".")[1]));
    if (decodedJwt.expiry * 1000 < Date.now()) return false;
    return true;
  } catch (e) {
    return false;
  }
};

export const registerUser = async (payload: any) => {
  try {
    const response = await makeRequest(
      AUTH_ENDPOINTS.REGISTER,
      METHODS.POST,
      payload
    );
    console.log(response);
    if (!response) {
      throw new Error(`Something wen't wrong during resgistration!`);
    }
    return response.data;
  } catch (error) {
    console.error(`registerUser > ${error.toString()}`);
    throw error;
  }
};

export const loginUser = async (payload: any) => {
  try {
    
    const response = await makeRequest(
      AUTH_ENDPOINTS.LOGIN,
      METHODS.POST,
      payload
    );
    
    if (!response) {
      throw new Error(`Something wen't wrong during login!`);
    }
    return response.data;
  } catch (error) {
    console.error(`registerUser > ${error.toString()}`);
    throw error;
  }
};

export const verifyEmailService = async (payload: any) => {
  try {
    const response = await makeRequest(
      AUTH_ENDPOINTS.VERIFY_EMAIL,
      METHODS.POST
    );
    
    if (!response) {
      throw new Error("Something went wrong during email verification!");
    }
    console.log(response.data);
    return response.data;

    
  } catch (error) {
    console.error(`verifyEmailService > ${error.toString()}`);
    throw error;
  }
};

export const forgetPassword = async (payload: any) => {
  try {
    const response = await makeRequest(
      AUTH_ENDPOINTS.FORGET_PASSWORD,
      METHODS.POST,
      payload
    );
    if (!response) {
      throw new Error(`Something wen't wrong during login!`);
    }
    return response.data;
  } catch (error) {
    console.error(`registerUser > ${error.toString()}`);
    throw error;
  }
};




export const resetPassword = async (payload: any) => {
  try {
    const response = await makeRequest(
      AUTH_ENDPOINTS.RESET_PASSWORD,
      METHODS.POST,
      payload // Directly send the payload
    );
    if (!response) {
      throw new Error(`Something went wrong during password reset!`);
    }
    return response.data;
  } catch (error) {
    console.error(`resetPassword > ${error.toString()}`);
    throw error;
  }
};
