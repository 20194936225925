import React, { useState, useEffect } from "react";
import { SiOpenai } from "react-icons/si";
import { HiUser } from "react-icons/hi";
import { TbCursorText } from "react-icons/tb";
import { MdContentCopy } from "react-icons/md";
import { AiOutlineUser } from "react-icons/ai";
import { MdSettingsBackupRestore } from "react-icons/md";
import { setConverstions } from "app/chats/chats.action";
import { useDispatch, useSelector } from "react-redux";
import { Socket } from "Socket/socket";
import toast, { Toaster } from "react-hot-toast";
import { Conversation } from "app/chats/chats.action";
import Markdown from 'react-markdown'


const Message = (props: any) => {

  const { message, isLast, setConversation, chatId, setMessage, setShowEmptyChat, conversation, id , selectedModel, isDisabled } = props;
  const { role, content: text } = message;

  const isUser = role === "user";

  const dispatch = useDispatch();
  const chats = useSelector((state: any) => state.chats)

  const [isLoading, setIsLoading] = useState(false);
  const [copyId, setCopyId] = useState(null);


  const handleChatCopy = (text: any, id: any) => {
    navigator.clipboard.writeText(text);
    setCopyId(id);
    toast.success("Copied!")
  }


  const handleRegen = () => {
    setIsLoading(true);

    const newConversation = chats.conversation.filter((_: any, index: any) => index !== chats.conversation.length - 1);
    setConversation(newConversation);
    setShowEmptyChat(newConversation.length === 0);

    const regenConvo = chats.conversation.slice(0, -2);

    const newConvoObject = [...regenConvo,
    { content: newConversation[newConversation.length - 1].content, role: "user" },
    { content: "", role: "assistant" }
    ];
    
    dispatch(setConverstions(newConvoObject) as any);
    setConversation(newConvoObject)

    Socket.emit("regenerate-stream", {
      data: newConversation[newConversation.length - 1].content,
      chatId,
      messages: newConversation,
      agentName:chats.agentName
    })

    // Clean up previous listeners before adding a new one
    Socket.off(chatId);

    Socket.on(chatId, (data: any) => {
      setConversation((prevData: any) => {
        // Ensure to create a new array to trigger the state update
        const updatedConvo = [...prevData];
        updatedConvo[updatedConvo.length - 1].content += data;
        dispatch(setConverstions(updatedConvo) as any);
        return updatedConvo;
      });
    });
    setIsLoading(false);
  }

  useEffect(() => {
    setTimeout(() => {
      setCopyId("");
    }, 3000)
  }, [copyId])
  return (
    <>
      <Toaster
        toastOptions={{
          duration: 2000,
          style: {
            background: "#cdcdcd",
            color: "#fff",
          },
          success: {
            duration: 2000,
            iconTheme: {
              primary: "green",
              secondary: "black",
            },
          },
          error: {
            duration: 2000,
            iconTheme: {
              primary: "red",
              secondary: "black",
            },
          },
        }}
      />
      <div
        className={`group w-full text-white dark:text-gray-100"`}
      >
        <div className="text-base gap-4 md:gap-6 md:max-w-md lg:max-w-xl xl:max-w-3xl flex lg:px-0 m-auto w-full justify-end md:pl-0 pl-0 md:pr-0 pr-5">
          <div
            className={`inline-flex flex-row gap-5 md:gap-6 md:max-w-2xl p-4 md:py-6 lg:px-8 min-w-[25%] justify-start  ${
              isUser
                ? "flex-row-reverse bg-[#2f2f2f] rounded-xl my-1"
                : "lg:max-w-xl xl:max-w-3xl w-full"
            }`}
            style={{
              background: isUser 
                ? `linear-gradient(
                    rgba(0, 0, 0, 0.4), 
                    rgba(0, 0, 0, 0.4)
                  ), ${chats?.agentColor}`
                : undefined
            }}
          >
            <div className="w-8 flex flex-col relative items-end">
                {isUser ? (
                  <div className="relative h-9 w-9 p-0 rounded-[50%] text-white flex items-center justify-center bg-black/75 text-opacity-100r">
                  <AiOutlineUser className="h-5 w-5 text-white" />
                  </div>
                ) : (
                  <div className="relative h-16 w-16 p-0 rounded-[50%] text-white flex items-center justify-center text-opacity-100r">
                      <img 
                      src={chats?.agentIcon} 
                      alt="Agent Icon" 
                      className="rounded-[50%] m-0 border-2 border-[rgba(0,0,0,0.3)]"
                      />
                  </div>
                )}
              <div className="text-xs flex items-center justify-center gap-1 absolute left-0 top-2 -ml-4 -translate-x-full group-hover:visible !invisible">
                <button
                  disabled
                  className="text-gray-300 dark:text-gray-400"
                ></button>
                <span className="flex-grow flex-shrink-0">1 / 1</span>
                <button
                  disabled
                  className="text-gray-300 dark:text-gray-400"
                ></button>
              </div>
            </div>
            <div className="relative flex w-[calc(100%-50px)] flex-col gap-1 md:gap-3 ">
              <div className="flex flex-grow flex-col gap-3">
                <div
                  className={`min-h-15 flex flex-col items-start gap-4 whitespace-pre-wrap break-words`}
                >
                  <div
                    className={`markdown prose break-words dark:prose-invert dark ${
                      isUser ? "text-left w-full" : "w-full"
                    }`}
                  >
                    {!isUser && text === null ? (
                      <TbCursorText className="h-6 w-6 animate-pulse" />
                    ) : (
                      <>
                        <p style={{ color: chats?.agentColor || "#fff" }}>
                          <Markdown
                            //className={`w-full text-[${chats?.agentColor}]`}
                            className={`w-full text-[#d3d3d3]`}
                          >
                            {text}
                          </Markdown>
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex justify-start">
                {!isUser && text?.length > 0 && (
                  <>
                    <small
                      className="bg-gray-900 rounded-lg px-5 py-1 flex items-center border-2 border-[rgba(0,0,0,0.3)] cursor-pointer text-[rgba(255,255,255,0.6)] hover:text-[rgba(255,255,255,0.8)]"
                      onClick={() => handleChatCopy(text, id)}
                      style={{
                        background: `none`
                      }}
                    >
                      <MdContentCopy className="mr-2 text-[14px] w-[20px] h-[20px] flex-shrink-0" />
                      {copyId === id ? "Copied" : "Copy"}
                    </small>
                    {isLast && !isDisabled && (
                      <small
                        onClick={handleRegen}
                        className="bg-gray-900 rounded-lg px-3 py-1 ml-5 flex items-center cursor-pointer border-2 border-[rgba(0,0,0,0.3)] text-[rgba(255,255,255,0.6)] hover:text-[rgba(255,255,255,0.8)]"
                        style={{ minWidth: "130px", minHeight: "32px", background: `none`}}
                      >
                        <MdSettingsBackupRestore className="text-[14px] w-[20px] h-[20px] flex-shrink-0" />
                        <span className="ml-2 mr-2">Regenerate Response</span>
                      </small>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Message;
