import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ChatGptIcon from "../assets/listingcopy-logo.png";
import { connect } from "react-redux";
import { forgetUserPassword } from "../app/auth/auth.action";
import { ToastContainer, toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import 'react-toastify/dist/ReactToastify.css';  // Import Toastify CSS
import { checkAutoLogin } from "app/user/user.service";

type ForgotPasswordPayload = {
  forgetUserPassword: (email: string) => any;
  loading: boolean;
  error: string;
  success: boolean;
};

const ForgotPass = ({ forgetUserPassword, loading, error, success }: ForgotPasswordPayload) => {
  const [email, setEmail] = useState<string>("");

  const navigate = useNavigate()

  useEffect(() => {
    if (success) {
      toast.success("Password reset email sent successfully!");
    }
    if (error) {
      toast.error(`Password reset failed: ${error}`);
    }
  }, [success, error]);

  const handleForgotPassword = () => {
    if (email) {
      forgetUserPassword(email);
    }
  };

  useEffect(() => {
    if(checkAutoLogin()){
      navigate("/")
    }
  },[])

  return (
    <>
      <section className="flex items-center justify-center">
        <div className="h-screen flex flex-col items-center justify-center md:w-[30%] sm:w-[50%] w-[90%]">
          <img src={ChatGptIcon} width={200} alt="gpt-icon" />
          <h1 className="lg:text-3xl text-2xl font-bold my-4">Forgot Password</h1>
          <div className="flex flex-col w-full">
            <input
              placeholder="Email Address *"
              className="border border-green-600 rounded placeholder:text-base px-5 py-3 my-3 outline-none"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <button
            className="bg-[#10a37f] w-full rounded-lg p-2 my-5 text-white sm:text-[18px] text-base"
            onClick={handleForgotPassword}
            disabled={loading}  // Disable button while loading
          >
            {loading ? <ClipLoader color="white" size={25}/> : "Send"}
          </button>

        </div>
      </section>
      <ToastContainer />  {/* Add ToastContainer for toast messages */}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    success: state.auth.success,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  forgetUserPassword: (email: string) => {
    dispatch(forgetUserPassword(email));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPass);
