import React, { useEffect } from "react";
import "./styles/globals.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ChatPage from "./components/ChatPage";
import SignUp from "./components/Signup";
import Login from "./components/Login";
import ForgotPass from "./components/ForgotPass";
import VerifyEmail from "components/VerifyEmail";
import ResetPass from "components/ResetPass";
import ProtectedRoute from "components/protectedRoutes";

function App() {
  const ProtectedDashboard = ProtectedRoute(ChatPage)
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/chat/:chatId" element={<ProtectedDashboard />} />
        <Route path="/" element={<ProtectedDashboard />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-pass" element={<ForgotPass />} />
        <Route path="/reset-password" element={<ResetPass />} />
        <Route path="/verify/:token" element={<VerifyEmail/>} />
        <Route path="/chat/:chatId" element={<ChatPage />} />
        <Route path="/" element={<ChatPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
