import React from "react"
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { DeleteChat , CreateChat } from "app/chats/chats.action";
import { useDispatch , useSelector } from "react-redux";

const DeletePopup = ({ delPop, setDelPop , delId }: { delPop: boolean, setDelPop: React.Dispatch<React.SetStateAction<boolean>> , delId:string }) => {

  const navigate  = useNavigate();
  const dispatch = useDispatch();

  const Chats = useSelector((state: any) => state.chats);


  const handleDeleteChat = async () => {  
    await dispatch(DeleteChat(delId) as any);
    if(Chats.chatList.length === 1){
      await dispatch(CreateChat({agentName:Chats.agentName}) as any);
    }
    setDelPop(false);
  }

    return (
      <>
       <Toaster
          toastOptions={{
            duration: 2000,
            style: {
              background: '#cdcdcd',
              color: '#fff',
            },
            success: {
              duration: 2000,
              iconTheme: {
                primary: 'green',
                secondary: 'black',
              },
            },
            error: {
              duration: 2000,
              iconTheme: {
                primary: 'red',
                secondary: 'black',
              },
            },
          }}
        />
  <Dialog open={delPop} onClose={setDelPop} className="relative z-50">
    <DialogBackdrop
      transition
      className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
    />
  
    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <DialogPanel
          transition
          className="relative transform overflow-hidden rounded-lg bg-[#232323] text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 w-full max-w-md sm:max-w-lg lg:max-w-xl"
        >
          <section className="text-gray-600 body-font">
            <div className="container px-5 py-12 mx-auto flex flex-wrap sm:flex-row flex-col">
              <div className="flex mx-auto justify-center items-center flex-wrap mb-10 w-full">
              <h2 className="text-[22px] text-white" > Are you sure you want to delete this chat? </h2>
              </div>
              <div className="w-full px-12 flex justify-end items-center" >
              <button onClick={() => setDelPop(false)} className="flex mt-4 justify-center items-center  sm:w-auto text-center w-full text-white bg-red-500 border-0 py-2 px-6 focus:outline-none hover:bg-red-500 rounded">
              No
              </button>
              <button onClick={handleDeleteChat} className="flex mt-4 ml-5 justify-center items-center  sm:w-auto text-center w-full text-white bg-indigo-600 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded">
              Yes
              </button>
              </div>

            </div>
          </section>
        </DialogPanel>
      </div>
    </div>
  </Dialog>
  
      </>
    )
  }

  export default DeletePopup