import axios from "axios";
import { getBaseApi } from "../../config/Environment";

export const makeRequest = (
  endpoint: string,
  method: string,
  payload: any = null,
  addedHeaders: any = null,
  params: any = null
) => {
  let headers: any = {};

  const token = localStorage.getItem("accesstoken");
  if (token) headers.Authorization = `Bearer ${token}`;
  if (addedHeaders) headers = { ...headers, ...addedHeaders };
  switch (method) {
    case "GET":
      return axios.get(`${getBaseApi()}${endpoint}`, { headers });
    case "POST":
      return axios.post(`${getBaseApi()}${endpoint}`, payload, { headers });
    case "DELETE":
      return axios.delete(`${getBaseApi()}${endpoint}`, { headers, params });
    case "PUT":
      return axios.put(`${getBaseApi()}${endpoint}`, payload, {
        headers,
        params,
      });
    case "PATCH":
      return axios.patch(`${getBaseApi()}${endpoint}`, payload, {
        headers,
        params,
      });
    default:
      return false;
  }
};

export const getPdfRequest = (
  endpoint: string,
  payload: any = null,
  addedHeaders: any = null
) => {
  let headers: any = {};
  const token = localStorage.getItem("token");
  if (token) headers.Authorization = `Bearer ${token}`;
  if (addedHeaders) headers = { ...headers, ...addedHeaders };
  return axios.post(`${getBaseApi()}${endpoint}`, payload, {
    headers,
    responseType: "blob",
  });
};
