import React from 'react';
import { useEffect , useState } from 'react';
import Chat from './Chat';
import '../styles/globals.css'
import MobileSiderbar from './MobileSidebar';
import Sidebar from './Sidebar';


const ChatPage = () => {
    const [isComponentVisible, setIsComponentVisible] = useState(false);


    const toggleComponentVisibility = () => {
      setIsComponentVisible(!isComponentVisible);
    };
    
    return(
        <>
         <main className="overflow-hidden w-full h-screen relative flex">
    {isComponentVisible ? (
      <MobileSiderbar toggleComponentVisibility={toggleComponentVisibility} isComponentVisible={isComponentVisible} setIsComponentVisible={setIsComponentVisible} />
    ) : null}
    <div className="dark hidden flex-shrink-0 bg-gray-900 md:flex md:w-[260px] md:flex-col">
      <div className="flex h-full min-h-0 flex-col ">
        <Sidebar toggleComponentVisibility={toggleComponentVisibility} />
      </div>
    </div>
    <Chat toggleComponentVisibility={toggleComponentVisibility}/>
    </main>
        </>
    )
}
export default ChatPage;
