export const CHAT_ACTIONS = {
    CREATE_CHAT: "CREATE_CHAT",
    CHAT_CREATED : "CHAT_CREATED",
    CREATE_CHAT_FAILED : "CREATE_CHAT_FAILED",
    GETTING_ALL_CHATS : "GETTING_ALL_CHATS",
    GET_ALL_CHATS : "GET_ALL_CHATS",
    GET_ALL_CHATS_FAILED : "GET_ALL_CHATS_FAILED",
    DELETING_CHAT : "DELETING_CHAT",
    DELETE_CHAT : "DELETE_CHAT",
    DELETE_CHAT_FAILED : "DELETE_CHAT_FAILED",
    UPDATING_CHAT_NAME: "UPDATING_CHAT_NAME",
    UPDATED_CHAT_NAME: "UPDATED_CHAT_NAME",
    UPDATE_CHAT_NAME_FAILED : "UPDATE_CHAT_NAME_FAILED",
    CONVERSATIONS : "CONVERSATION_START",
    CONVERSATIONS_SUCCESSSFULL : "CONVERSATION_SUCCESSSFULL",
    CONVERSATIONS_FAILED : "CONVERSATION_FAILED",
    SET_LOCAL_CONVERSATIONS : "SET_LOCAL_CONVERSATIONS",
    GETTING_ALL_AGENTS : "GETTING_ALL_AGENTS",
    GET_ALL_AGENTS : "GET_ALL_AGENTS",
    GET_ALL_AGENTS_FAILED : "GET_ALL_AGENTS_FAILED",
    SET_AGENT_NAME : "SET_AGENT_NAME",
    SET_AGENT_COLOR : "SET_AGENT_COLOR"
}   

export const CHAT_ENDPOINTS = {
    CREATE_CHAT : "chats/create-chat",
    GET_ALL_CHATS : "chats/get-all-chats",
    DELETE_CHAT : "chats/delete-chat",
    UPDATE_CHAT_NAME : "chats/update-chat-name",
    CONVERSATION : "chats/get-all-chats-conversation",
    GET_ALL_AGENTS: "chats/get-agent"
}