import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  AiOutlineMessage,
  AiOutlinePlus,
  AiOutlineUser,
  AiOutlineSetting,
} from "react-icons/ai";
import { BiLinkExternal } from "react-icons/bi";
import { FiMessageSquare } from "react-icons/fi";
import { MdLogout } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { MdOutlineEditNote } from "react-icons/md";
import { AiOutlineCheckSquare } from "react-icons/ai";

import { useDispatch, useSelector } from "react-redux";
import {
  CreateChat,
  GetAllChats,
  DeleteChat,
  UpdateChatName,
  setAgentColor,
} from "app/chats/chats.action";
import { useNavigate } from "react-router-dom";

// profile settings
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import {
  UpdateProfile,
  UpdatePassword,
  DeleteUser,
  GetUser,
} from "app/user/user.action";
import { BsShieldLock } from "react-icons/bs";
import toast, { Toaster } from "react-hot-toast";
import { resetState } from "app/auth/auth.action";
import DeletePopup from "./DeletePopup";

type SidebarProps = {
  toggleComponentVisibility: () => void;
  isComponentVisible?: any;
  setIsComponentVisible?: any;
};

const Sidebar: React.FC<SidebarProps> = ({
  toggleComponentVisibility,
  isComponentVisible,
  setIsComponentVisible,
}) => {
  const params = useParams();
  const { chatId } = params;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let isFirst = useRef(true);
  let isNavigate = useRef(true);
  const Chats = useSelector((state: any) => state.chats);

  const [chatEdit, setChatEdit] = useState(false);
  const [inputChat, setInputChat] = useState("");
  const [editChatId, setEditChatId] = useState("");
  const [open, setOpen] = useState(false);
  const [delPop, setDelPop] = useState(false);
  const [delId, setDelId] = useState("");

  const handleNewChat = async () => {
    if (Chats.chatList.length === 0) {
      await dispatch(CreateChat({ agentName: Chats.agentName }) as any);
      if (isComponentVisible) {
        setIsComponentVisible(false);
      }
    } else {
      const isEmptyChatExist = Chats?.chatList.some(
        (chat: any) => chat.conversationCount === 0
      );
      if (isEmptyChatExist) {
        toast.error("Empty Chat Alredy exist!");
      } else {
        await dispatch(CreateChat({ agentName: Chats.agentName }) as any);
        if (isComponentVisible) {
          setIsComponentVisible(false);
        }
      }
    }
  };

  const handleChatNavigate = (id: any, color: any) => {
    navigate(`/chat/${id}`);
    dispatch(setAgentColor(color) as any);
    // toggleComponentVisibility()
    if (isComponentVisible) {
      setIsComponentVisible(false);
    }
  };

  const handleEditChat = (id: any) => {
    setEditChatId(id);
    setChatEdit(true);
  };

  const handleDeleteChat = async (id: any) => {
    setDelId(id);
    setDelPop(true);
  };

  const updateChat = async (id: any, name: any) => {
    const agentName = Chats?.agentName;
    await dispatch(UpdateChatName(id, name, agentName) as any);
    await setChatEdit(false);
    await dispatch(GetAllChats() as any);
  };

  const handleLogout = () => {
    dispatch(resetState() as any);
    localStorage.removeItem("accesstoken");
    navigate("/login");
  };

  useEffect(() => {
    if (Chats?.chatList?.length === 0 && Chats?.agentName) {
      if (isFirst.current === true) {
        dispatch(CreateChat({ agentName: Chats.agentName }) as any);
        if (isComponentVisible) {
          setIsComponentVisible(false);
        }
      }
      isFirst.current = false;
    }
  }, [Chats?.chatList, Chats?.agentName]);

  useEffect(() => {
    if (Chats?.chatList?.length > 0) {
      if (isNavigate.current === true) {
        navigate(`/chat/${Chats?.chatList[0]?.id}`);
      }
      isNavigate.current = false;
    }
  }, [Chats.chatList]);

  useEffect(() => {
    dispatch(GetAllChats() as any);
    if (Chats?.chats?.id) {
      navigate(`/chat/${Chats?.chats?.id}`);
    }
  }, [Chats.chats]);

  useEffect(() => {
    dispatch(GetAllChats() as any);
  }, [Chats.conversation]);

  // useEffect(() => {
  //   Chats.chatList.map((chat: any) => {
  //     dispatch(setAgentColor(chat.AIAgent.color) as any);
  //   });
  // }, [Chats.chatList]);
  return (
    <>
      <Example open={open} setOpen={setOpen} />
      <DeletePopup delPop={delPop} setDelPop={setDelPop} delId={delId} />
      <div className="scrollbar-trigger flex h-full w-full flex-1 items-start border-white/20" 
  style={{
    background: (() => {
      const selectedChat = Chats?.chatList?.find((chat: any) => chat.id === chatId);
      return selectedChat?.AIAgent?.color 
        ? `linear-gradient(
            rgba(0, 0, 0, 0.6), 
            rgba(0, 0, 0, 0.6)
          ), ${selectedChat.AIAgent.color}`
        : 'rgba(0, 0, 0, 0.4)'; // Fallback color
    })(),
  }}
      >
        <nav className="flex h-full flex-1 flex-col space-y-1 p-2">
          <a
            onClick={handleNewChat}
            className="flex py-3 px-3 items-center gap-3 rounded-md hover:bg-gray-500/10 transition-colors duration-200 text-white cursor-pointer text-sm mb-1 flex-shrink-0 border border-white/20"
          >
            <AiOutlinePlus className="h-4 w-4" />
            New chat
          </a>
          <div className="flex-col flex-1 overflow-y-auto border-b border-white/20">
            <div className="flex flex-col gap-2 pb-2 text-gray-100 text-sm">
              {Chats?.chatList?.map((chat: any, index: any) => {
                  const isSelected = chat.id === chatId;
                  const chatColor = chat?.AIAgent?.color;
                return (
                  <a
                    key={index}
                    className={`flex py-3 px-3 items-center gap-3 relative rounded-md cursor-pointer break-all hover:pr-4 group`}
                    style={{
                      background: isSelected 
                        ? `linear-gradient(
                            rgba(0, 0, 0, 0.8), 
                            rgba(0, 0, 0, 0.8)
                          ), ${chatColor}`
                        : undefined,
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.background = `linear-gradient(
                        rgba(0, 0, 0, 0.8), 
                        rgba(0, 0, 0, 0.8)
                      ), ${chatColor}`;
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.background = isSelected 
                        ? `linear-gradient(
                            rgba(0, 0, 0, 0.8), 
                            rgba(0, 0, 0, 0.8)
                          ), ${chatColor}`
                        : 'transparent';
                    }}
                  >
                    <img
                      className="h-8 w-8 rounded-[50%] object-cover"
                      src={chat?.AIAgent?.imageIcon}
                      alt="icon"
                    />
                    <div
                      onClick={() =>
                        handleChatNavigate(chat.id, chat?.AIAgent?.color)
                      }
                      className="flex-1 text-ellipsis max-h-8 break-all relative"
                    >
                      {chatEdit && editChatId === chat.id ? (
                        <>
                          <input
                            type="text"
                            onChange={(e) => setInputChat(e.target.value)}
                            value={inputChat}
                            className="bg-transparent w-[100%]"
                          />
                        </>
                      ) : (
                        <>
                          <p
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              color: "#d3d3d3",
                            }}
                          >
                            {chat.chatName}
                            <small style={{ fontSize: '60%' }}>{chat?.AIAgent?.name}</small>
                          </p>
                        </>
                      )}
                      {/* <div className="absolute inset-y-0 right-0 w-8 z-10 bg-gradient-to-l from-gray-900 group-hover:from-[#2A2B32]"></div> */}
                    </div>
                    {chatEdit ? (
                      <AiOutlineCheckSquare
                        className="text-[20px]"
                        onClick={() => updateChat(chat.id, inputChat)}
                      />
                    ) : (
                      <MdOutlineEditNote
                        className="text-[20px]"
                        onClick={() => handleEditChat(chat.id)}
                      />
                    )}
                    <MdDeleteOutline
                      className="text-[20px]"
                      onClick={() => handleDeleteChat(chat.id)}
                    />
                  </a>
                );
              })}
            </div>
          </div>
          <a
            onClick={() => setOpen(true)}
            className="flex py-3 px-3 items-center gap-3 rounded-md hover:bg-gray-500/10 transition-colors duration-200 text-white cursor-pointer text-sm"
          >
            <AiOutlineSetting className="h-4 w-4" />
            Settings
          </a>
          <a
            onClick={handleLogout}
            className="flex py-3 px-3 items-center gap-3 rounded-md hover:bg-gray-500/10 transition-colors duration-200 text-white cursor-pointer text-sm "
          >
            <MdLogout className="h-4 w-4" />
            Log out
          </a>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;

const Example = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [settingsTab, setSettingsTab] = useState(1);
  const [fullName, setFullname] = useState("");
  const [oldPassword, setOldPassword] = useState("");

  const [newPassword, setNewPassword] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const User = useSelector((state: any) => state.user);

  const handleUpdateProfile = async () => {
    const userName = User?.user.email;
    await dispatch(UpdateProfile(userName, fullName) as any);

    console.log(User);
  };

  const handleUpdatePassword = async () => {
    const password = oldPassword;
    await dispatch(UpdatePassword(password, newPassword) as any);
  };

  useEffect(() => {
    setFullname(User.user.fullName);
  }, [User.user]);

  useEffect(() => {
    dispatch(GetUser() as any);
  }, [settingsTab]);

  return (
    <>
      <Toaster
        toastOptions={{
          duration: 2000,
          style: {
            background: "#cdcdcd",
            color: "#fff",
          },
          success: {
            duration: 2000,
            iconTheme: {
              primary: "green",
              secondary: "black",
            },
          },
          error: {
            duration: 2000,
            iconTheme: {
              primary: "red",
              secondary: "black",
            },
          },
        }}
      />
      <Dialog open={open} onClose={setOpen} className="relative z-50">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-[{{chat.AIAgent.color}}] text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 w-full max-w-md sm:max-w-lg lg:max-w-xl"
            >
              <section className="text-gray-600 body-font">
                <div className="container px-5 py-12 mx-auto flex flex-wrap sm:flex-row flex-col">
                  <div className="flex mx-auto justify-center items-center flex-wrap mb-10 w-full">
                    <a
                      onClick={() => setSettingsTab(1)}
                      className={`${
                        settingsTab === 1
                          ? "bg-[#2f2f2f] border-indigo-500 text-indigo-500"
                          : ""
                      } sm:px-6 py-3 w-full sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none tracking-wider rounded-t cursor-pointer`}
                    >
                      <AiOutlineUser className="text-[20px] mr-1" />
                      Update Profile
                    </a>
                    <a
                      onClick={() => setSettingsTab(2)}
                      className={`${
                        settingsTab === 2
                          ? "bg-[#2f2f2f] border-indigo-500 text-indigo-500"
                          : ""
                      } sm:px-6 py-3 w-full sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium inline-flex items-center leading-none border-gray-200 tracking-wider cursor-pointer`}
                    >
                      <BsShieldLock className="text-[20px] mr-1" />
                      Update Password
                    </a>
                  </div>
                  {settingsTab === 1 && (
                    <div className="flex flex-col items-center w-full">
                      <div className="w-full sm:w-auto">
                        <label
                          htmlFor="fullname"
                          className="block text-sm font-medium leading-6 text-gray-300"
                        >
                          Fullname
                        </label>
                        <div className="relative mt-2 rounded-md shadow-sm">
                          <input
                            id="fullname"
                            name="fullname"
                            type="text"
                            placeholder="enter fullname"
                            value={fullName}
                            onChange={(e) => setFullname(e.target.value)}
                            className="block w-full rounded-md bg-[#2f2f2f] border-0 py-1.5 pl-3 px-4 text-gray-300 ring-1 ring-inset outline-none ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                        <button
                          onClick={handleUpdateProfile}
                          className="flex mt-4 sm:w-auto text-center w-full justify-center items-center text-white bg-indigo-600 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded"
                        >
                          {User.loading ? (
                            <div
                              className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-gray-500"
                              role="status"
                              aria-label="loading"
                            ></div>
                          ) : (
                            "Update Profile"
                          )}
                        </button>
                      </div>
                    </div>
                  )}
                  {settingsTab === 2 && (
                    <div className="flex flex-col items-center w-full">
                      <div className="w-full sm:w-auto">
                        <label
                          htmlFor="old-password"
                          className="block text-sm font-medium leading-6 text-gray-300"
                        >
                          Old Password
                        </label>
                        <div className="relative mt-2 rounded-md shadow-sm">
                          <input
                            id="old-password"
                            name="old-password"
                            type="password"
                            value={oldPassword}
                            placeholder="enter old password"
                            className="block w-full rounded-md bg-[#2f2f2f] border-0 py-1.5 pl-3 px-3 outline-none text-gray-300 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={(e) => setOldPassword(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="mt-3 w-full sm:w-auto">
                        <label
                          htmlFor="new-password"
                          className="block text-sm font-medium leading-6 text-gray-300"
                        >
                          New Password
                        </label>
                        <div className="relative mt-2 rounded-md shadow-sm">
                          <input
                            id="new-password"
                            name="new-password"
                            type="password"
                            value={newPassword}
                            placeholder="enter new password"
                            className="block w-full rounded-md bg-[#2f2f2f] border-0 py-1.5 pl-3 px-3 outline-none text-gray-300 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={(e) => setNewPassword(e.target.value)}
                          />
                        </div>
                        <button
                          onClick={handleUpdatePassword}
                          className="flex mt-4 justify-center items-center  sm:w-auto text-center w-full text-white bg-indigo-600 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded"
                        >
                          {User.loading ? (
                            <div
                              className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-gray-500"
                              role="status"
                              aria-label="loading"
                            ></div>
                          ) : (
                            "Update Password"
                          )}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </section>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
};
