import { CHAT_ACTIONS } from "./chats.constants";
import { createChat , getAllChats , deleteChat, updateChatName , conversation , getAllAgents } from "./chats.service";

export const CreateChat = (agentName:any) => {
    return async (dispatch: any) => {
      dispatch({ type: CHAT_ACTIONS.CREATE_CHAT });
      try {
        const data: any = await createChat(agentName);
        return dispatch({ type: CHAT_ACTIONS.CHAT_CREATED , payload:data });
      } catch (error: any) {
        console.error(error.message);
        return dispatch({
          type: CHAT_ACTIONS.CREATE_CHAT_FAILED,
          payload: error.message,
        });
      }
    };
  };

export const GetAllChats = () => {
    return async (dispatch: any) => {
      dispatch({ type: CHAT_ACTIONS.GETTING_ALL_CHATS });
      try {
        const data: any = await getAllChats();
        console.log(data);
        return dispatch({ type: CHAT_ACTIONS.GET_ALL_CHATS , payload:data });
      } catch (error: any) {
        console.error(error.message);
        return dispatch({
          type: CHAT_ACTIONS.GET_ALL_CHATS_FAILED,
          payload: error.message,
        });
      }
    };
  };

  export const DeleteChat = (chatId:any) => {
    return async (dispatch: any) => {
      dispatch({ type: CHAT_ACTIONS.GETTING_ALL_CHATS });
      try {
        const data: any = await deleteChat(chatId);
        console.log(data);
        return dispatch({ type: CHAT_ACTIONS.DELETE_CHAT , payload:data });
      } catch (error: any) {
        console.error(error.message);
        return dispatch({
          type: CHAT_ACTIONS.GET_ALL_CHATS_FAILED,
          payload: error.message,
        });
      }
    };
  };

  export const UpdateChatName = (chatId:any , chatName?:any , agentName?:any) => {
    return async (dispatch: any) => {
      dispatch({ type: CHAT_ACTIONS.UPDATING_CHAT_NAME });
      try {
        const data: any = await updateChatName(chatId , chatName , agentName);
        console.log(data);
        return dispatch({ type: CHAT_ACTIONS.UPDATED_CHAT_NAME , payload:data });
      } catch (error: any) {
        console.error(error.message);
        return dispatch({
          type: CHAT_ACTIONS.UPDATE_CHAT_NAME_FAILED,
          payload: error.message,
        });
      }
    };
  };

  export const Conversation = (chatId:any) => {
    return async (dispatch: any) => {
      dispatch({ type: CHAT_ACTIONS.CONVERSATIONS });
      try {
        const data: any = await conversation(chatId);
        return dispatch({ type: CHAT_ACTIONS.CONVERSATIONS_SUCCESSSFULL , payload:data });
      } catch (error: any) {
        console.error(error.message);
        return dispatch({
          type: CHAT_ACTIONS.CONVERSATIONS_FAILED,
          payload: error.message,
        });
      }
    };
  };

  export const setConverstions = (data:any) => {
    return (dispatch:any) => {
      dispatch({type : CHAT_ACTIONS.SET_LOCAL_CONVERSATIONS , payload:data})
    }
  }

  export const setAgentName = (data:any , icon:any) => {
    return (dispatch:any) => {
      dispatch({type : CHAT_ACTIONS.SET_AGENT_NAME , payload:{data , icon}})
    }
  }
  export const setAgentColor = (data:any) => {
    return (dispatch:any) => {
      dispatch({type : CHAT_ACTIONS.SET_AGENT_COLOR , payload:{data}})
    }
  }


  export const GetAllAgents = () => {
    return async (dispatch: any) => {
      dispatch({ type: CHAT_ACTIONS.GETTING_ALL_AGENTS });
      try {
        const data: any = await getAllAgents();
        return dispatch({ type: CHAT_ACTIONS.GET_ALL_AGENTS , payload:data });
      } catch (error: any) {
        console.error(error.message);
        return dispatch({
          type: CHAT_ACTIONS.GET_ALL_AGENTS_FAILED,
          payload: error.message,
        });
      }
    };
  };
