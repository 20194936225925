import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { FiSend } from "react-icons/fi";
import { BsChevronDown, BsPlusLg } from "react-icons/bs";
import { RxHamburgerMenu } from "react-icons/rx";
// import useAnalytics from '../hooks/useAnalytics.tsx'
import useAutoResizeTextArea from "../hooks/useAutoResizeTextArea";
import Message from "./Message";
import { DEFAULT_OPENAI_MODEL } from "../shared/Constants";
import"../App.css"
import { useDispatch, useSelector } from "react-redux";
import { Conversation, GetAllAgents, setAgentName, setConverstions , UpdateChatName, GetAllChats, setAgentColor } from "app/chats/chats.action";
import { GetUser } from "app/user/user.action";
import { Socket } from "Socket/socket";
import { useNavigate } from "react-router-dom";

const Chat = (props: any) => {
  const { toggleComponentVisibility } = props;
  const params = useParams();
  const { chatId } = params;
  const [isLoading, setIsLoading] = useState(false);
  const [agentsDropdown, setAgentsDropdown] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showEmptyChat, setShowEmptyChat] = useState(true);
  const [conversation, setConversation] = useState<any[]>([]);
  const [message, setMessage] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedModelColor , setSelectedModelColor] = useState("#e1e1e1");
  const [modelIcon, setModelIcon] = useState("");
  const [isChatDisabled, setDisabled] = useState(false)

  const navigate = useNavigate()

  // const textAreaRef = useAutoResizeTextArea();
  // const textAreaRef = useAutoResizeTextArea();
  const textAreaRef = useRef(null);

  const bottomOfChatRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  const chats = useSelector((state: any) => state.chats);

  useEffect(() => {
    // Set up the WebSocket listener only once when the component mounts
    Socket.on(chatId, handleSocketData);
    return () => {
      // Clean up the WebSocket listener when the component unmounts
      Socket.off(chatId, handleSocketData);
    };
  }, [chatId]);

  useEffect(() => {
    dispatch(Conversation(chatId) as any);
  }, [chatId]);

  useEffect(() => {
    if (chats.conversation) {
      setConversation(chats.conversation);
      setShowEmptyChat(chats.conversation.length === 0);
    }
  }, [chats.conversation]);

  useEffect(() => {
    if (bottomOfChatRef.current) {
      bottomOfChatRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [conversation]);

  const handleSocketData = (data: any) => {
    setConversation((prevData) => {
      const updatedConvo = [...prevData];
      updatedConvo[updatedConvo.length - 1].content += data;
      dispatch(setConverstions(updatedConvo) as any);  // Update the Redux store
      return updatedConvo;
    });
  };

  useEffect(()=>{

  },[chats])

  const sendMessage = async (e: any) => {
    e.preventDefault();

    // Don't send empty messages
    if (message.length < 1) {
      setErrorMessage("Please enter a message.");
      return;
    } else {
      setErrorMessage("");
    }

    setIsLoading(true);

    const newConvoObject = [
      ...chats.conversation,
      { content: message, role: "user" },
      { content: "", role: "assistant" },
    ];
    setConversation(newConvoObject);
    dispatch(setConverstions(newConvoObject) as any);

    // Clear the message & remove empty chat
    setMessage("");
    setShowEmptyChat(false);


    // Emit the startStream event
    Socket.emit("startStream", {
      data: message,
      chatId,
      messages: newConvoObject,
      agentName: selectedModel
    });

    setIsLoading(false);
  };

  const handleKeypress = (e: any) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      sendMessage(e);
      e.preventDefault();
    }
  };

  const handleAgent = async (name:any , icon:any , color:any) => {
    console.log(name , icon);
    await dispatch(setAgentName(name , icon) as any);
    await dispatch(setAgentColor(color) as any)
    setSelectedModel(name);
    setSelectedModelColor(color)
    setAgentsDropdown(false);
    const chat = chats.chatList.find((chat:any) => {
      if(chat.id === chatId){
        return chat
      }
    })
    await dispatch(UpdateChatName(chatId, chat.chatName , name) as any)
    await dispatch(GetAllChats() as any);
  }

  useEffect(() => {
    dispatch(GetUser() as any); 
    dispatch(GetAllAgents() as any) 
  },[chatId])

  useEffect(() => {
    setSelectedModel(chats?.agents[0]?.name);
    setSelectedModelColor(chats?.agents[0]?.color)
    setModelIcon(chats?.agents[0]?.imageIcon)
    dispatch(setAgentName(chats?.agents[0]?.name , chats?.agents[0]?.imageIcon))
  },[chats.agents])

  useEffect(() => {
    chats?.chatList?.forEach((chat:any) => {
      if(chat.id === chatId){
        if(chats.agents){
          setDisabled(true)
          let isExist = chats.agents.find((agnt:any)=>agnt.name === chat?.AIAgent?.name)
          if(isExist)setDisabled(false)
        }
        setSelectedModel(chat?.AIAgent?.name)
        setSelectedModelColor(chat?.AIAgent?.color)
        setModelIcon(chat?.AIAgent?.imageIcon);
        dispatch(setAgentName(chat?.AIAgent?.name , chat?.AIAgent?.imageIcon) as any)
      }
    })
  },[chats.chatList,chats.agents])



  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "24px";
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [message]);


  return (
    <div className="flex max-w-full flex-1 flex-col" 
    style={{
      'color': '#d3d3d3',
      '--c': selectedModelColor,
      background: 'linear-gradient(to bottom, color-mix(in srgb, var(--c), #fff 0%), color-mix(in srgb, var(--c), #000 50%))'
      } as React.CSSProperties}>
      <div className="sticky top-0 z-10 flex items-center border-b border-white/20 bg-gray-800 pl-1 pt-1 text-gray-200 sm:pl-3 md:hidden">
        <button
          type="button"
          className="-ml-0.5 -mt-0.5 inline-flex h-10 w-10 items-center justify-center rounded-md hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white dark:hover:text-white"
          onClick={toggleComponentVisibility}
        >
          <span className="sr-only">Open sidebar</span>
          <RxHamburgerMenu className="h-6 w-6 text-white" />
        </button>
        <h1 className="flex-1 text-center text-base font-normal">+ New chat</h1>
        <button type="button" className="px-3">
          <BsPlusLg className="h-6 w-6" />
        </button>
      </div>
      <div className="relative h-full w-full transition-width flex flex-col overflow-hidden items-stretch flex-1">
        <div className="flex-1 overflow-hidden">
          <div className="react-scroll-to-bottom--css-ikyem-79elbk h-full dark:bg-gray-800">
            <div className="react-scroll-to-bottom--css-ikyem-1n7m0yu" 
              style={{
                '--c': selectedModelColor,
                background: 'linear-gradient(to bottom, color-mix(in srgb, var(--c), #fff 0%), color-mix(in srgb, var(--c), #000 50%))'
                } as React.CSSProperties}
            >
              {!showEmptyChat && chats?.conversation.length > 0 ? (
                <div className="flex flex-col items-center text-xl pb-10">
                  <div className="flex w-full items-center justify-center gap-1 border-b border-black/10 bg-gray-50 p-3 text-gray-500 dark:border-gray-900/50 dark:bg-gray-900 dark:text-gray-300 mb-5"
                        style={{
                          'color': '#d3d3d3',
                          '--c': selectedModelColor,
                          background: 'linear-gradient(to bottom, color-mix(in srgb, var(--c), #fff 0%), color-mix(in srgb, var(--c), #000 50%))'
                          } as React.CSSProperties}
                      >
                    
                  <img src={chats?.agentIcon} className="border-2 border-[rgba(0,0,0,0.2)]" style={{marginRight:"10px" , borderRadius:"50%" , width:"100px" , height:"100px"}} alt="icon" /> {selectedModel}
                  </div>
                  {chats?.conversation.map((message: any, index: any) => (
                    <Message
                      isDisabled={isChatDisabled}
                      key={index}
                      message={message}
                      isLast={index === chats.conversation.length - 1}
                      setMessage={setMessage}
                      conversation={conversation}
                      setConversation={setConversation}
                      setShowEmptyChat={setShowEmptyChat}
                      chatId={chatId}
                      id={message.id}
                    />
                  ))}
                  <div className="w-full h-32 md:h-48 flex-shrink-0"></div>
                  <div ref={bottomOfChatRef}></div>
                </div>
              ) : null}
              {showEmptyChat ? (
                <div className="py-10 relative w-full flex flex-col h-full">
                  <div className="flex items-center justify-center gap-2">
                    <div className="relative w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
                  <span style={{color : '#d3d3d3'}} >Select your employee:</span>
                      <button
                        className="relative flex w-full cursor-pointer flex-col rounded-md border border-black/10 bg-white py-2 pl-3 pr-10 text-left focus:border-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 dark:border-white/20 dark:bg-gray-900 sm:text-sm align-center"
                        style={{
                          'color': '#d3d3d3',
                           background: `linear-gradient(
                                      rgba(0, 0, 0, 0.3), 
                                      rgba(0, 0, 0, 0.3)
                                    ), ${selectedModelColor}`
                                            } as React.CSSProperties}
                        type="button"
                        onClick={() => setAgentsDropdown(!agentsDropdown)}
                      >
                        <span className="inline-flex w-full truncate">
                          <span className="flex items-center gap-1 truncate text-white mt-1">
                            {!isChatDisabled && selectedModel && <img src={chats?.agentIcon} style={{marginRight:"1.25rem" , borderRadius:"50%" , width:"50px" , height:"50px"}} alt="icon" />}
                            <span style={{color : '#d3d3d3'}} >{!isChatDisabled && selectedModel ? selectedModel :'Employee Unavailable'}</span>
                          </span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <BsChevronDown className="h-4 w-4 text-gray-400" />
                        </span>
                      </button>
                      {
                        agentsDropdown && 
                        <div className="absolute top-[100px] w-full bg-gray-900 border-2 border-gray-400 p-4 rounded-lg" 
                              style={{
                                'color': '#d3d3d3',
                                background: `linear-gradient(
                                            rgba(0, 0, 0, 0.3), 
                                            rgba(0, 0, 0, 0.3)
                                          ), ${selectedModelColor}`
                                                  } as React.CSSProperties}
                        >
                        {
                          chats?.agents?.map((agent:any , index:any) => {
                            return (
                              <>
                                <li onClick={() => handleAgent(agent.name, agent.imageIcon, agent.color)} 
                                className="flex items-center mb-4 py-2 pl-4 cursor-pointer rounded-lg" 
                                style={{
                                  background: `linear-gradient(
                                              rgba(0, 0, 0, 0.4), 
                                              rgba(0, 0, 0, 0.4)
                                            ), ${agent.color}`
                                                    } as React.CSSProperties} 
                                key={index}>
                                  <img className="mr-5 w-[50px] h-[50px] rounded-full" src={agent.imageIcon} alt={`${agent.name} icon`} />
                                  <span style={{ color: '#d3d3d3'}}>
                                    {agent.name}
                                  </span>
                                </li>
                              </>
                            );                            
                          })
                        }
                        </div>
                      }
                    </div>
                  </div>
                  { !isChatDisabled && selectedModel ?
                  <h1 className="text-2xl sm:text-4xl font-semibold text-center text-gray-200 dark:text-gray-600 flex gap-2 items-center justify-center h-screen" style={{ color: '#d3d3d3'}}>
                  Hi, I'm {selectedModel.split(':')[0]} <br/>How can I help?
                  
                  </h1>
                  :
                  <h1 className="text-2xl sm:text-4xl font-semibold text-center text-gray-200 dark:text-gray-600 flex gap-2 items-center justify-center h-screen" style={{ color: '#d3d3d3'}}>
                   This employee is not available!
                  
                  </h1>
                  }
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 left-0 w-full dark:border-white/20 md:border-transparent md:dark:border-transparent">
  <form className="stretch mx-2 flex flex-row gap-3 last:mb-2 md:mx-4 md:last:mb-6 lg:mx-auto lg:max-w-2xl xl:max-w-3xl">
    <div className="relative flex flex-col h-full flex-1 md:flex-col">
      {errorMessage ? (
        <div className="mb-2 md:mb-0">
          <div className="h-full flex ml-1 md:w-full md:m-auto md:mb-2 gap-0 md:gap-2 justify-center">
            <span className="text-red-500 text-sm">{errorMessage}</span>
          </div>
        </div>
      ) : null}
      {!isChatDisabled && selectedModel && <div className="flex py-2 flex-row w-full space-x-5 flex-grow md:py-2 md:pl-8 relative border border-black/10 dark:border-gray-900/50 dark:text-white rounded-[30px] shadow-[0_0_10px_rgba(0,0,0,0.10)] dark:shadow-[0_0_15px_rgba(0,0,0,0.10)] pl-5" 
      style={{
        'color': '#d3d3d3',
         background: `linear-gradient(
                    rgba(0, 0, 0, 0.2), 
                    rgba(0, 0, 0, 0.2)
                  ), ${selectedModelColor}`
                          } as React.CSSProperties}
                          >
        <div className="w-[95%] pt-1 rounded-[30px]">
          <textarea
            ref={textAreaRef}
            value={message}
            tabIndex={0}
            style={{
              maxHeight: "150px",
              overflowY: "auto",
            }}
            placeholder="Send a message..."
            className="m-0 w-full resize-none border-0 bg-transparent p-0 pr-2 focus:ring-0 focus-visible:ring-0 dark:bg-transparent pl-2 md:pl-0 custom-scroll "
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleKeypress}
          ></textarea>
        </div>
        <div className="flex w-[5%]">
          <button
            disabled={isLoading || message?.length === 0}
            onClick={sendMessage}
            className="absolute p-1 rounded-md bottom-3 md:bottom-3 pb-1 bg-transparent disabled:bg-gray-500 right-2 md:right-3 disabled:opacity-40"
          >
            <FiSend className="h-5 w-5 mr-1 text-white" />
          </button>
        </div>
      </div>}
    </div>
  </form>
  <div className="px-3 pt-2 pb-3 text-center text-xs text-white/50 dark:text-white/50 md:px-4 md:pt-2 relative">
    <span className="text-[10px]">
      Lai Chatbot may produce inaccurate information about people, places, or facts.
    </span>
  </div>
</div>


      </div>
    </div>
  );
};

export default Chat;
