import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ChatGptIcon from "../assets/listingcopy-logo.png";
import { connect, useDispatch } from "react-redux";
import { loginuser, resetState } from "../app/auth/auth.action";
import { ToastContainer, toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import 'react-toastify/dist/ReactToastify.css';  
import { checkAutoLogin } from "app/user/user.service";


type LoginPayload = {
  loginuser: (email: string, password: string) => any;
  loading: boolean;
  error: string;
  success: boolean;
  
};

const Login = ({ loginuser, loading, error, success  }: LoginPayload) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [localSuccess, setLocalSuccess] = useState<boolean>(false); // Local state for success

const nav = useNavigate()

const dispatch =useDispatch()

useEffect(() => {
  dispatch(resetState()); 
}, [dispatch]);


  useEffect(() => {
    if (success) {
      toast.success("Logged in successfully!");
      nav("/");
    }
    if (error) {
      toast.error(`Login failed: ${error}`);
    }
  }, [success, error]);

  const handleLogin = () => {
    if (email && password) {
      loginuser(email, password);
    } else {
      toast.error("Please enter both email and password");
    }
  };

  useEffect(() => {
    if(checkAutoLogin()){
      nav("/")
    }
  },[])
  return (
    <>
      <section className="flex items-center justify-center">
        <div className="h-screen flex flex-col items-center justify-center md:w-[30%] sm:w-[50%] w-[90%]">
          <img src={ChatGptIcon} width={200} alt="gpt-icon" />
          <h1 className="lg:text-3xl text-2xl font-bold my-4">Teams</h1>
          <div className="flex flex-col w-full">
            <input
              placeholder="Email Address"
              className="border placeholder:text-base border-green-600 rounded px-5 py-3 my-3 outline-none"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              placeholder="Password"
              className="border placeholder:text-base border-green-600 rounded px-5 py-3 my-3 outline-none"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Link to="/forgot-pass">
              <small className="font-semibold mt-[-10px]">Forgot Password?</small>
            </Link>
          </div>
          <button
            className="bg-[#06a0e7] w-full rounded-lg p-2 my-5 text-white sm:text-[18px] text-base"
            onClick={handleLogin}
            disabled={loading}  // Disable button while loading
          >
            {loading ? <ClipLoader color="white" size={25}/>  : "Login"}
          </button>

          {/* 
          <p className="sm:text-base text-sm">
            Don't have an account?{" "}
            <Link to="/signup" className="text-[#06a0e7]">Sign Up</Link> 

          </p>
          */}
        </div>
      </section>
      <ToastContainer />  {/* Add ToastContainer for toast messages */}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    success: state.auth.success,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  loginuser: (email: string, password: string) => {
    dispatch(loginuser(email, password));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
