import { makeRequest } from "../shared/request-service";
import { METHODS } from "../shared/requests.constants";
import { CHAT_ENDPOINTS } from "./chats.constants";


export const createChat = async (agentName:any) => {
    try {
      const response = await makeRequest(
        CHAT_ENDPOINTS.CREATE_CHAT,
        METHODS.POST,
        agentName
      );
      if (!response) {
        throw new Error(`Something wen't wrong in Creating chat!`);
      }
      return response.data;
    } catch (error) {
      console.error(`CreateChat > ${error.toString()}`);
      throw error;
    }
  };

  export const getAllChats = async () => {
    try {
      const response = await makeRequest(
        CHAT_ENDPOINTS.GET_ALL_CHATS,
        METHODS.GET,
      );
      if (!response) {
        throw new Error(`Something wen't wrong in getting all chat!`);
      }
      return response.data;
    } catch (error:any) {
      if (error.response && error.response.status === 401) {
        console.error('Unauthorized: Token Expired');
        localStorage.clear()
        window.location.replace('/')
      } else {
        console.error(`get all chats > ${error.toString()}`);
      }
      throw error;
    }
  };

  export const updateChatName = async (chatId: any , chatName:any , agentName:any) => {
    try {
      const response = await makeRequest(
        CHAT_ENDPOINTS.UPDATE_CHAT_NAME,
        METHODS.POST,
        { chatId , chatName , agentName }
      );
      if (!response) {
        throw new Error(`Something wen't wrong in updating chat name!`);
      }
      return response.data;
    } catch (error) {
      console.error(`UPDATE CHAT NAME > ${error.toString()}`);
      throw error;
    }
  };

  export const deleteChat = async (chatId: any) => {
    try {
      const response = await makeRequest(
        CHAT_ENDPOINTS.DELETE_CHAT,
        METHODS.POST,
        { chatId }
      );
      if (!response) {
        throw new Error(`Something wen't wrong in deleting chat!`);
      }
      return response.data;
    } catch (error) {
      console.error(`get all chats > ${error.toString()}`);
      throw error;
    }
  };

  export const conversation = async (chatId: any) => {
    try {
      const response = await makeRequest(
        CHAT_ENDPOINTS.CONVERSATION + `/${chatId}`,
        METHODS.GET,
      );
      if (!response) {
        throw new Error(`Something wen't wrong in conversation!`);
      }
      return response.data;
    } catch (error:any) {
      if (error.response && error.response.status === 401) {
        console.error('Unauthorized: Token Expired');
        localStorage.clear()
        window.location.replace('/')
      } else {
        console.error(`get all chats > ${error.toString()}`);
      }
      console.error(`conversation > ${error.toString()}`);
      throw error;
    }
  };

  export const getAllAgents = async () => {
    try {
      const response = await makeRequest(
        CHAT_ENDPOINTS.GET_ALL_AGENTS,
        METHODS.GET,
      );
      if (!response) {
        throw new Error(`Something wen't wrong in getting agents!`);
      }
      return response.data;
    } catch (error) {
      console.error(`get all agents > ${error.toString()}`);
      throw error;
    }
  };