import { AUTH_ACTIONS } from "app/auth/auth.constant";
import { registerUser, loginUser, verifyEmailService, forgetPassword, resetPassword } from "./auth.service";

export const register = (fullName: string, email: string, password: string) => {
  return async (dispatch: any) => {
    dispatch({ type: AUTH_ACTIONS.REGISTERING_USER });
    try {
      const payload = {
        fullName,
        email,
        password,
      };
  const data = await registerUser(payload);
      dispatch({ type: AUTH_ACTIONS.USER_REGISTERED,payload:data });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: AUTH_ACTIONS.REGISTRATION_FAILED,
        payload: error.response?.data?.error || error.message,
      });
    }
  };
};

export const loginuser = (email: string, password: string) => {
  return async (dispatch: any) => {
    dispatch({ type: AUTH_ACTIONS.LOGGING_USER });
    try {
      const payload = {
        email,
        password,
      };
      const data: any = await loginUser(payload);
      console.log(data.accessToken);
      localStorage.setItem("accesstoken", data.accessToken);
      dispatch({ type: AUTH_ACTIONS.LOGGEDIN_USER });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: AUTH_ACTIONS.LOGGEDIN_USER_FAILED,
        payload: error.response?.data?.error || error.message,
      });
    }
  };
};

export const verifyUserEmail = (token: string) => {
  return async (dispatch: any) => {
    dispatch({ type: AUTH_ACTIONS.VERIFYING_EMAIL });
    try {
      const data = await verifyEmailService(token);
      console.log(data);
      
      dispatch({ type: AUTH_ACTIONS.EMAIL_VERIFIED, payload: data });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: AUTH_ACTIONS.EMAIL_VERIFICATION_FAILED,
        payload: error.message,
      });
    }
  };
};

export const forgetUserPassword = (email: string) => {
  return async (dispatch: any) => {
    dispatch({ type: AUTH_ACTIONS.FORGETTING_PASSWORD });
    try {
      const payload = { email };
      await forgetPassword(payload);
      dispatch({ type: AUTH_ACTIONS.FORGET_PASSWORD });
    } catch (error: any) {
      console.error(error.message);
      dispatch({
        type: AUTH_ACTIONS.FORGETTING_PASSWORD_FAILED,
        payload: error.message,
      });
    }
  };
};

export const resetUserPassword = (password: string) => {
  return async (dispatch: any) => {
    dispatch({ type: AUTH_ACTIONS.RESET_PASSWORD });
    try {
      const token = localStorage.getItem("accesstoken");
      if (!token) {
        throw new Error("Token is missing");
      }
      const payload = { password };
      await resetPassword(payload);
      dispatch({ type: AUTH_ACTIONS.RESET_PASSWORD_SUCCESS });
    } catch (error: any) {
      console.error(error);
      dispatch({
        type: AUTH_ACTIONS.RESET_PASSWORD_FAILED,
        payload: error.message,
      });
    }
  };
};

export const resetState = () =>{

  return async (dispatch: any) => {

    dispatch({ type: AUTH_ACTIONS.RESET_STATE });
    
  }

}


